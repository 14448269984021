import { Component, Mixins } from 'vue-property-decorator';
import template from './Login.Template.vue';
import LoginValidator from './Login.Validator';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Getter, Action } from 'vuex-class';
import { User } from '@/store/modules/auth/types';

@Component({
    metaInfo: {
        title: 'Login',
    },
    ...template,
    name: 'Login',
})
export default class Login extends Mixins(LoginValidator) {
    public $refs!: {
        form: HTMLFormElement,
    };

    // La promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    // Méthode de login.
    @Action(AuthStoreMethods.LOGIN)
    public login: (payload: { email: string, username: string, password: string }) => Promise<User | null>;

    private e1: boolean = true;
    private password: string = '';
    private username: string = '';
    private email: string = '';
    private tryingToLogIn: boolean = false;
    private authError: any = null;

    public created() {
        this.getUserProfile.then((profile: any) => {
            if (!!profile) {
                this.$router.push({ name: 'home' }).catch(() => { /*Laisse vide express */ });
            }
        });
    }
    public submit() {
        if (this.$refs.form.validate()) {
            this.tryToLogIn();
        }
    }
    public clear(): void {
        this.$refs.form.reset();
    }

    public resetValidation(): void {
        this.$refs.form.resetValidation();
    }

    public tryToLogIn(): void {
        this.tryingToLogIn = true;
        // Reset the authError if it existed.
        this.authError = null;
        this.login({ email: this.email, username: this.username, password: this.password })
            .then(() => {
                if (this.$route.query.redirectFrom) {
                    this.forceRefresh(this.$route.query.redirectFrom as string);
                } else {
                    this.forceRefresh('/mes-dossiers');
                }
            })
            .catch((error: User | string | null) => {
                this.authError = error;
            }).finally(() => this.tryingToLogIn = false);
    }

    public forceRefresh(url: string): void {
        // Forcer le refresh lorsqu'on redirige depuis sur la page de connection,pour forcer Vue à prendre en compte le User connecté.
        window.location.replace(url);
    }
}
